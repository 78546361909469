<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addDoctor'})" :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">الأطباء</dashboard-page-title>
    <main-table :fields="fields" :allStatus="allStatus" list_url="admin/doctors" :baseURL="VUE_APP_VETE_LINK"  :additional-url="`&status=accepted`"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      allStatus: [
        {
          value: 'pending',
          text: 'قيد الإنتظار'
        },
        {
          value: 'refused',
          text: 'تم الرفض'
        },
        {
          value: 'accepted',
          text: 'نشط'
        }
      ],
      fields: [
        { label: 'ID', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم الطبيب', key: 'full_name', class: 'text-right' },
        { label: 'الحالة الوظيفية', key: 'type', class: 'text-right' },
        { label: 'عدد الكشوفات', key: 'examinations_count', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ التسجيل', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
